import {Typography} from '@mui/material'
import {graphql} from 'gatsby'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import * as React from 'react'
import {Headline, Section} from '../components'
import {CookieConsentProvider} from '../context'
import {PageLayout} from '../layout'

const NotFoundPage: React.FC<{data: any}> = ({data}) => {
  const {t} = useTranslation()
  const {language} = useI18next()

  return (
    <PageLayout
      subpage
      seo={{
        title: t('notFound.title'),
        description: t('notFound.description'),
        siteName: data.site.siteMetadata.title,
        lang: language,
      }}
    >
      <CookieConsentProvider
        language={language}
        title={t('cookie.consent.title')}
        body={t('cookie.consent.body')}
        acceptLabel={t('cookie.consent.agree')}
        rejectLabel={t('cookie.consent.decline')}
        linkLabel={t('cookie.consent.link')}
      />

      <Section>
        <Headline variant="h1">{t('notFound.title')}</Headline>
        <Typography variant="h3" component="h2">
          {t('notFound.description')}
        </Typography>
      </Section>
    </PageLayout>
  )
}

export const query = graphql`
  query NotFoundPageQuery($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default NotFoundPage
