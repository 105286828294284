import {SEO, SEOProps} from '@hypesync/seo'
import {Box, styled, useMediaQuery} from '@mui/material'
import {graphql, StaticQuery} from 'gatsby'
import * as React from 'react'
import {Helmet} from 'react-helmet'
import {TFunction} from 'react-i18next'
import {Footer, FooterProps, Header} from '../../components'

export interface PageLayoutProps {
  seo?: SEOProps
  subpage?: boolean
  t?: TFunction
  footerProps?: Partial<FooterProps>
}

const query = graphql`
  query {
    logoLight: file(relativePath: {eq: "finastic-logo-light.png"}) {
      childImageSharp {
        fluid(maxWidth: 190, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    raportLogoLight: file(relativePath: {eq: "finastic-logo-light.png"}) {
      childImageSharp {
        fluid(maxWidth: 190, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    logoDark: file(relativePath: {eq: "finastic-logo-dark.png"}) {
      childImageSharp {
        fluid(maxWidth: 190, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    raportLogoDark: file(relativePath: {eq: "finastic-logo-dark.png"}) {
      childImageSharp {
        fluid(maxWidth: 190, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

const StyledPageLayout = styled(Box)(({theme}) => ({
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  paddingTop: theme.spacing(7),
  backgroundColor: theme.palette.background.default,
  minHeight: '100%',
  justifyContent: 'space-between',
  flex: '1 1 auto',
}))

export const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  seo: {lang, ...seo},
  subpage,
  footerProps,
  t,
}) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem('gatsby-i18next-language')
    }
  }, [])

  return (
    <StyledPageLayout>
      <StaticQuery query={query}>
        {(data) => {
          let logo = prefersDarkMode
            ? data.logoLight.childImageSharp
            : data.logoDark.childImageSharp

          if (lang === 'pl') {
            logo = prefersDarkMode
              ? data.raportLogoLight.childImageSharp
              : data.raportLogoDark.childImageSharp
          }

          return (
            <>
              <Box>
                <Header
                  logo={logo}
                  logoAlt={t ? t('navigation.image.logo.alt') : 'Finastic logo'}
                  subpage={subpage}
                />

                {children}
              </Box>

              <Footer {...footerProps} subpage={subpage} />

              {seo && <SEO {...seo} url={t ? t('url') : ''} />}

              <Helmet htmlAttributes={{lang: lang}} />
            </>
          )
        }}
      </StaticQuery>
    </StyledPageLayout>
  )
}

export default PageLayout
